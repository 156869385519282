var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-sm text-gray-700" }, [
        _vm._v("アクセスグラフ"),
      ]),
      _vm._m(0),
      _c("D3LineChart", {
        attrs: { config: _vm.chartConfig, datum: _vm.chartData },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "rounded-md bg-gray-100 p-2 text-xs mt-2" },
      [
        _c("p", [
          _vm._v(
            "折れ線グラフにマウスオーバーしていただくと、対応する作品名が表示されます。"
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }