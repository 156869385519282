var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full rounded-md border shadow mt-4 overflow-hidden" },
    [
      _c("table", { staticClass: "w-full" }, [
        _c("thead", { staticClass: "bg-gray-200" }, [
          _c("tr", { staticClass: "text-left" }, [
            _c("th", { staticClass: "p-1" }, [_vm._v("バイヤー名")]),
            !_vm.isSingle
              ? _c("th", { staticClass: "p-1" }, [_vm._v("作品名")])
              : _vm._e(),
            _c("th", { staticClass: "p-1" }, [_vm._v("アクセス種類")]),
            _c("th", { staticClass: "p-1" }, [_vm._v("日時(日本時間)")]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.logs, function (record) {
            return _c("tr", { key: record.id, staticClass: "border" }, [
              _c("td", { staticClass: "p-1 underline" }, [
                _c(
                  "a",
                  {
                    staticClass: "text-blue-500",
                    attrs: {
                      href: "/ch/vod_profile/" + record.viewedBy.uuid,
                      target: "_blank",
                    },
                  },
                  [_vm._v(" " + _vm._s(record.viewedBy.company_name) + " ")]
                ),
              ]),
              !_vm.isSingle
                ? _c("td", { staticClass: "p-1 underline" }, [
                    _c(
                      "a",
                      {
                        staticClass: "text-blue-500",
                        attrs: {
                          href:
                            "/movies/detail/" +
                            record.movie.uuid +
                            "?tab=premium",
                          target: "_blank",
                        },
                      },
                      [_vm._v(" " + _vm._s(record.movie.film_name) + " ")]
                    ),
                  ])
                : _vm._e(),
              _c("td", { staticClass: "p-1" }, [
                _vm._v(
                  _vm._s(
                    record.type === 1
                      ? "作品詳細ページ閲覧"
                      : record.type === 2
                      ? "スクリーナー視聴"
                      : "ファイルダウンロード"
                  )
                ),
              ]),
              _c("td", { staticClass: "p-1" }, [
                _vm._v(_vm._s(_vm._f("datetime")(record.created_at))),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }